import React, { FC, useState } from 'react';
import { Icon, IconButton, TextInput } from '@kontentino/ui';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import useBoolean from 'utils/hooks/useBoolean';
import AIGenerateButton from 'app/modules/aiContent/components/AIGenerateButton';

type Props = {
  onSubmit(value: string): void;
  disabled?: boolean;
};

const TextEditorAiContentRefineTextSelectionInput: FC<Props> = ({
  onSubmit,
  disabled,
}) => {
  const [value, setValue] = useState('');
  const [focused, setFocused] = useBoolean(false);
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!focused && !!value) {
      ref.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <TextInput
        ref={ref}
        disabled={disabled}
        data-name="ai-content-text-selection-refine-text_input"
        placeholder="✨ Describe how you want to refine the text"
        className="tw-h-8 tw-w-full tw-bg-transparent focus:tw-bg-purple-10/50 focus:tw-ring-purple-100 "
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setFocused.off();
            onSubmit(e.currentTarget.value);
          }
        }}
        value={value}
        onFocus={() => {
          setFocused.on();
        }}
        onBlur={() => {
          setFocused.off();
        }}
        iconAfter={
          value ? (
            <div className="-tw-mr-2 tw-flex tw-items-center tw-gap-2">
              <IconButton
                data-name="ai-content-text-selection-refine-text_clear-button"
                variant="ghost"
                size="small"
                onClick={() => {
                  setValue('');
                  ref.current?.focus();
                }}
                className="tw-text-grayscale-120"
              >
                <Icon icon={faXmark} />
              </IconButton>
              <AIGenerateButton
                type="button"
                data-name="ai-content-text-selection-refine-text_custom-prompt-generate-button"
                onClick={() => {
                  onSubmit(value);
                  setFocused.off();
                }}
                disabled={disabled}
                size="small"
              />
            </div>
          ) : undefined
        }
      />
    </div>
  );
};

export default TextEditorAiContentRefineTextSelectionInput;
